import React from "react";
import { Link as GLink } from "gatsby"
import PropTypes from "prop-types";

export class Link extends React.Component {
    render() {
        return (
            <GLink className={this.props.className} to={this.props.location}>
                {this.props.children}
            </GLink>
        );
    }
}

Link.propTypes = {
    className: PropTypes.any,
    location: PropTypes.string.isRequired,
};