import React from "react";
import {name, acronym} from './contentAux';
import {Pages} from '../config/pages';
import {Link} from './link';
import * as styles from './title.module.scss';


export const Title = React.forwardRef((props, ref) => (
  <div ref={ref} className={styles.title}>
    <Link location={Pages.home} className={styles.fullTitle.concat(" ", props.onHomepage ? styles.purple : "")}>{name}</Link>
    <Link location={Pages.home}  className={styles.acronym.concat(" ", props.onHomepage ? styles.purple : "")}>{acronym}</Link>
  </div>
));



