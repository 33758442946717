import React from "react";
import PropTypes from "prop-types";
import {Link} from './link';

import * as styles from './menu.module.scss';



export class MenuItem extends React.Component {
    constructor(props) {
        super(props);

        
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.props.focusImg(this.props.index);
    }

    handleMouseLeave() {
        this.props.blurImg();
    }

    getMenuItemClass() {
        const act = this.props.activePage ? `${styles.menuItem} ${styles.activePage}` : styles.menuItem;
        return this.props.menuOpen ? `${styles.menuOpen} ${act}` : act;
    }

    render() {
        return (
        	<Link location={this.props.menuItem.location} className={this.getMenuItemClass().concat(" ", this.props.onHomepage ? styles.purple : "")}>
                <div onMouseEnter={this.props.onHomepage ? this.handleMouseEnter : undefined} onMouseLeave={this.props.onHomepage ? this.handleMouseLeave : undefined}>
                    {this.props.menuItem.name}
                </div>

            </Link>
        );
    }}


MenuItem.propTypes = {
    onHomepage: PropTypes.bool.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    focusImg: PropTypes.func,
    blurImg: PropTypes.func,
    inFocus: PropTypes.bool,
    index: PropTypes.number.isRequired,
    activePage: PropTypes.bool.isRequired,
    menuItem: PropTypes.object.isRequired,

};

