import * as React from 'react';
import Helmet from 'react-helmet';
import favicon from '../../static/favicon.ico';
import * as styles from './page.module.scss';

export class Page extends React.Component {
	

    render() {
        return (
            <div className={styles.page} >
                <Helmet>
                    <link rel="icon" href={favicon} />
                    <title>KÖTESZ</title>
                </Helmet>
                {this.props.children}
            </div>
        );
    }
}