import * as React from 'react';
import PropTypes from "prop-types";
import * as styles from './footerText.module.scss';
import getYear, {name, email} from '../../common/contentAux';
import {Pages} from '../../config/pages';



export class FooterText extends React.Component {

	getTextClass() {
		return this.props.onHomepage ? `${styles.text} ${styles.onHomepage}` : styles.text;
	}

    render() {
        return (
        	
      
            <p className={this.getTextClass()}><span className={styles.name}>{name}</span> &copy; {getYear()} {this.props.onHomepage && <br/>} | {email} | <a href={Pages.elnokseg.admin} className={styles.elnoksegLink}>Elnökség</a></p>
    
        );
    }
}

FooterText.propTypes = {

    onHomepage: PropTypes.bool.isRequired,
       
};

FooterText.defaultProps = {
  onHomepage: false,  

};
