import React from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import {menuItems} from'./contentAux';
import {MenuItem} from './menuItem';

import * as styles from './menu.module.scss';






class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.menu = React.createRef();

        this.state = { 
            menuOpen: false, 
        };

     this.openMenu = this.openMenu.bind(this);
     this.closeMenu = this.closeMenu.bind(this);

    }

    closeMenu() {
        this.setState({menuOpen: false});
            this.props.setMenuHeight(0);
        
    }

    handleClickOutside = evt => {
        this.closeMenu();
    }

    openMenu() {
        this.setState({menuOpen: true});
            const h = this.menu.current.getBoundingClientRect().height * 4 + 44.2;
            this.props.setMenuHeight(h);
        
    }

    componentDidMount() {
        window.addEventListener('resize', this.closeMenu);
        

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.closeMenu);



    }

    

    

    render() {
        return (
        	<div  className={`${styles.menu} ${this.props.onHomepage && styles.onHomePage}`} >
                {!this.state.menuOpen && <p onClick={this.openMenu} className={styles.menuLabel.concat(" ", this.props.onHomepage ? styles.purple : "")}>menü</p>}
                <div className={styles.innerMenu} ref={this.menu}>{menuItems.map((item, i) => 
                        <MenuItem 
                        key={i} 
                        index={i} 
                        menuItem={item} 
                        focusImg={this.props.focusImg}
                        blurImg={this.props.blurImg}
                        inFocus={this.props.inFocus === i}
                        activePage = {this.props.activePage === i}
                        onHomepage={this.props.onHomepage}
                        menuOpen={this.state.menuOpen}
                        />)}</div>

            </div>
        );
    }}



Menu.propTypes = {
    onHomepage: PropTypes.bool.isRequired,
    activePage: PropTypes.number.isRequired,
    focusImg: PropTypes.func,
    blurImg: PropTypes.func,
    setMenuHeight: PropTypes.func,
    inFocus: PropTypes.number,

};



export default onClickOutside(Menu);


