import * as React from 'react';
import PropTypes from "prop-types";
import {cloudinaryUrlFromLink} from "../../../common/contentAux";
import * as styles from '../../../common/post.module.scss';

export const NewsImage = ({src, title}) => (
    <div className={styles.newsImage}>
        <img title={title} src={cloudinaryUrlFromLink(src, 768)} />
        <p>{title}</p>
    </div>
);

NewsImage.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
};

NewsImage.defaultProps = {
  title: '',  
};