import parse from 'html-react-parser';
import React from "react";
import img1 from "../content/img/landing/img1f.jpg";
import img2 from "../content/img/landing/img2f.jpg";
import img3 from "../content/img/landing/img3f.jpg";
import img4 from "../content/img/landing/img4f.jpg";
import {Pages} from "../config/pages";
import moment from "moment";
import localization from "moment/locale/hu";
import { NewsImage } from '../content/pages/hirek/newsImage';
import { Video } from '../content/pages/galeria/video';
import * as postStyles from '../common/post.module.scss';

export const purple = "#3c3856";
export const mobileWidth = 767;

export const menuItems = [
    {location: Pages.hirek.home, name: "hírek", img: img1},
    {location: Pages.esemenyek.home, name: "események", img: img3},
    {location: Pages.galeria.home, name: "galéria", img: img2},
    {location: Pages.rolunk.home, name: "rólunk", img: img4},
];


export const email = "kotesz.elnokseg@gmail.com";
export const address = "1215 Budapest, Árpád utca 1.";
export const name = "Kárpát-medencei Öregtáncos Együttesek Szövetsége";
export const acronym = "KÖTESZ";

export const showLogoScreenFor = 5000;
export const blocksOpenDelay = showLogoScreenFor + 600;
export const blocksOpenDuration = 1000; //also in block.scss

export default function getYear() {
    const d = new Date();
    return d.getFullYear();

}

export const arrowOpacity = 0.9;

//////////////////////////////
//////////////////////////////
//////////////////////////////

export function printTags(arr) {
    let toPrint = "";
    arr.forEach((e => toPrint += `${e}, `));
    toPrint = toPrint.slice(0, toPrint.length - 2);
    return toPrint;
}

export function getHeight(w, W, H) {
    return w * H / W;
}



function sortFuncSeqMaker(byKey, decreasing=true) {

    function sortFunc(a, b) {
        const aKeys = a[byKey];
        const bKeys = b[byKey];
        let aKey, bKey;
        for (const [i, aVal] of aKeys.entries()) {
            if (i === bKeys.length) {
                break;
            } else {
                const bVal = bKeys[i];
                if (aVal !== bVal) {
                    aKey = aVal;
                    bKey = bVal;
                    break;
                }
            }
            
        }

        if (!aKey) {
            return 0;
        } else if (decreasing ? aKey > bKey : bKey > aKey) {
            return -1;
        } else {
            return 1;
        }

    }

    return sortFunc;
}

export const soonestFirst = sortFuncSeqMaker("date");
export const lastUpdatedFirst = sortFuncSeqMaker("lastUpdated");

export function formatDate(num) {
    if (parseInt(num, 10) < 10) {
        return `0${num}`;
    } else {
        return num;
    };
}


export function formatCreatedDisplayDate(arr) {
    const [year, month, day, hours, minutes] = arr.map(d => formatDate(d));
    return `${year}.${month}.${day}. ${hours}:${minutes}`;

}

export const NODATE = 9999;

export function formatEventDisplayDate(arr) {
    const [year, month, dayFrom, dayTo] = arr.map(n => parseInt(n, 10));
    const months = ["január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december"];
    const m = month === NODATE ? "" : months[month - 1];
    let df = dayFrom === NODATE ? "" : `${dayFrom}`;
    const dt = dayTo === NODATE ? "" : `–${dayTo}.`;
    if (df && !dt) {
        df += "."
    }

    return `${year}. ${m} ${df}${dt}`;
}


const cloudinaryUrl = (filename, width) => `https://res.cloudinary.com/dx1thkmo3/image/upload/c_limit,w_${width}/${filename}`;
export const cloudinaryUrlFromLink = (link, width) => cloudinaryUrl(link.replace(/^https.*upload\//, ""), width);

export const moveImgsToDivInHtml = (html) => {
    function replace(domNode) {

        if (domNode.name === 'p' && 
            domNode.children && 
            domNode.children.map(child => child.name).includes('img')
        ) {
            const newChildren = domNode.children.map((child, i) => child.name === 'img' 
                ? <NewsImage 
                    key={i}
                    src={child.attribs.src} 
                    title={child.attribs.title}
                /> 
                : child
            );
            return <>{newChildren}</>;
        }

        if (domNode.name === 'p' && 
            domNode.children && 
            domNode.children.length === 1 &&
            domNode.children[0].data &&
            domNode.children[0].data.startsWith('videoId')
        ) {
            const data = domNode.children[0].data;
            const videoID = data.slice(8, data.length - 1);
            return <div className={postStyles.newsVideo}><Video video={{videoID}}/></div>;
        }
    }
    return parse(html, {replace});
}

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export function formatStringDateLocalToHu(stringDate, formatString) {
    moment.updateLocale('hu', localization);

    if (!stringDate) {
        return undefined;
    }

    const dateMoment = moment(stringDate);
    return dateMoment.format(formatString);
}