
export const devURL = 'https://kotesz.hu';

export const Pages = {
    home: "/",
    elnokseg: {
        admin: "https://kotesz.hu/admin",
        login: "/elnokseg/login",
        home: "/elnokseg/home",
        files: "/elnokseg/files",
        posts: "/elnokseg/posts"

    },
    hirek: {
        home: "/hirek",
    },

    galeria: {
        home: "/galeria",
        withCategory: "/galeria?kategoria="
    },
    esemenyek: {
        home: "/esemenyek",


    },
    rolunk: {
        home: "/rolunk",
    },



};