import * as React from 'react';
import PropTypes from "prop-types";
import * as galleryStyles from './gallery.module.scss';


export class Video extends React.Component {
    render() {   
        return (
            <div className={`${galleryStyles.imageItem} ${galleryStyles.videoItem}`}>
                <iframe 
                    width="100%" 
                    height="100%" 
                    src={`https://www.youtube.com/embed/${this.props.video.videoID}`} 
                    frameBorder="0" 
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
            </div>
        );
    }
}

Video.propTypes = {
    video: PropTypes.object.isRequired,
};
